.modal-content {
  background-color: var(--background-color);
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.modal-header,
.modal-body,
.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-top: none;
  border-bottom: none;
  gap: 1rem;
}
