.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 3rem;
  font-size: 1rem;
  color: var(--text-color);
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  transition: background-color 0.2s ease, font-weight 0.2s ease;
  border-radius: 0.5rem;
  gap: 0.7rem;
  cursor: pointer;
  font-weight: 200;
}

.accordion-header:hover {
  background-color: var(--navbar-color);
}

.accordion-header.active {
  font-weight: 500;
}

.accordion-chevron-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  line-height: 1rem;
  transition: transform 0.2s; /* speed of chevron icon rotation */
}

.accordion-chevron-icon.rotate {
  transform: rotate(-270deg);
}

.accordion-content {
  overflow: hidden;
  max-height: 10rem; /* max-height is dynamically calculated dynamically in the accordion component */
  opacity: 1; /* Set to 1 to ensure content is visible */
  visibility: visible;
  transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out,
    filter 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.accordion-content.closed {
  max-height: 0;
  opacity: 0; /* Hide content when closing */
  filter: blur(10px); /* Apply blur when content is closing */
  visibility: hidden;
  transition: max-height 0.2s ease-in-out, opacity 0.1s ease-in-out,
    filter 0.1s ease-in-out, visibility 0.1s ease-in-out;
}

