/* Animations */

:root {
  --animation-duration: 0.7s;
  --animation-timing-function: ease-out;
}

.fadeInLeft {
  animation: fadeInLeft var(--animation-duration)
    var(--animation-timing-function);
}

.fadeInRight {
  animation: fadeInRight var(--animation-duration)
    var(--animation-timing-function);
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-15%);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(15%);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
