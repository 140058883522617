.slider-container {
  display: flex;
  margin: 0rem;
  padding: 0rem;
  width: 100%;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 0.3rem;
  background: var(--dark);
  border-radius: 0.5rem;
  outline: none;
  opacity: 0.7; /* Set transparency (cross-browser) */
  -webkit-transition: 0.1s; /* 0.2 seconds transition on hover */
  transition: opacity 0.1s;
}

.slider:hover {
  opacity: 1; /* Fully opaque on hover */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 0.8rem; /* Width of the handle */
  height: 0.8rem; /* Height of the handle */
  border-radius: 50%;
  background: var(--primary-color-dark);
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 0.8rem; /* Width of the handle */
  height: 0.8rem; /* Height of the handle */
  border-radius: 50%;
  background: var(--primary-color-dark);
  cursor: pointer; /* Cursor on hover */
}
