.waveform-visualizer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 0.5rem; /* minimum height to ensure the waveform is visible */
  width: 100%;
  height: 100%;
}

.waveform-visualizer-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.5rem; /* used for temporary text references*/
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: relative; /* allows the comment icon to be positioned absolutely */
}

.waveform-visualizer-bar-container.disabled:hover,
.waveform-visualizer-bar-container.disabled {
  background-color: var(--transparent);
  cursor: progress;
}

.waveform-visualizer-bar-container:hover {
  background-color: var(--button-background);
  border-radius: 1rem;
}

.waveform-visualizer-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; /* this is a default as the height is dynamically set in the component */
  max-height: 100%; /* maximum height to ensure the peaks don't exceed the container */
  min-height: 1%; /* minimum height to ensure the waveform is visible at zero amplitude */
  background-color: var(--dark);
  border-radius: 1rem;
  transition:
    height 0.2s ease,
    background-color 0.1s ease; /* quick transition from active to non-active state */
}

.waveform-visualizer-bar.active {
  background-color: var(--tertiary-color);
  transition: background-color 0.6s ease; /* gradual transition from non-active to active state */
}

.waveform-visualizer-comment-icon {
  position: absolute;
  bottom: 0;
  z-index: 1; /* ensures the icon is on top of the waveform */
}
