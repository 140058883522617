/* ALERTS */
.info-alert,
.success-alert,
.warning-alert,
.danger-alert {
  padding: 1rem;
  border-radius: 0.7rem;
  width: 100%;
  font-weight: 300;
  background-color: var(--navbar-color);
}
.info-alert {
  box-shadow: 0 0 5px var(--info-color);
  color: var(--info-color);
}
.success-alert {
  box-shadow: 0 0 5px var(--success-color);
  color: var(--success-color);
}
.warning-alert {
  box-shadow: 0 0 5px var(--warning-color);
  color: var(--warning-color);
}
.danger-alert {
  box-shadow: 0 0 5px var(--danger-color);
  color: var(--danger-color);
}

.alert-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 100%; /* ensures above the footer */
  z-index: 1051; /* above the modal (1050) */
  padding: 1rem;
  gap: 1rem;
  max-width: 100%;
}

