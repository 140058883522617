/* SIDEBAR */

/* container used on mobile so the outside of the sidebar can be clicked to close it */
.sidebar-mobile-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.sidebar {
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  padding-left: 1rem;
  height: 100%; /* height allows the divs within the container to scroll */
  display: flex; /* set to flex to allow child sizing according */
  flex-direction: column; /* Stack children vertically to allow child sizing accordingly*/
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem; /* margin at the bottom to ensure all the posts are visible */
  overflow-y: scroll; /* allows the div to scroll */
  box-shadow: 0 0 10px var(--navbar-color);
  background-color: var(--background-color);
  z-index: 1; /* ensures the sidebar is above the main content and the sidebar background */
  overscroll-behavior: contain; /* prevents the page from scrolling when the sidebar is at the top or bottom of the scroll */
  transition: /* apply specific visibility elements to avoid excessive animations on sidebar load */
    filter 0.2s,
    opacity 0.2s,
    transform 0.2s,
    visibility 0.2s;

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar.is-mobile {
  position: absolute;
}

.sidebar.closed {
  filter: blur(10px);
  opacity: 0;
  transform: translateX(-100%);
  overflow: hidden;
  visibility: hidden;
}

/* SIDEBAR HEADER */

.sidebar-header {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
}
.sidebar-header-collective {
  display: flex;
  justify-content: center;
  transition: background-color 0.2s ease;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5em;
}
.sidebar-header-collective:hover {
  background-color: var(--navbar-color);
}
.sidebar-header-collective-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden; /* prevents the text from overflowing the container */
  margin-left: 0.5rem;
  margin-right: 0.2rem; /* slight margin to separate the text from the post button */
}

/* SIDEBAR PROJECT */

.sidebar-project-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.7rem; /* border radius here as well to ensure that the transition doesn't leave sharp edges */
  transition: background-color 0.2s ease;
}
.sidebar-project-card:hover {
  background-color: var(--navbar-color);
  border-radius: 0.7em;
}
.sidebar-project-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color);
  gap: 0.5rem;
  width: 100%;
}

/* SIDEBAR COLLECTIVE ICON */
.sidebar-collective-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  position: relative; /* allows dropdown icon to be on bottom right of button */
  flex-shrink: 0; /* Prevent the image from shrinking */
}
.sidebar-collective-icon:hover {
  opacity: 0.8;
}
.sidebar-collective-icon img {
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  object-fit: cover;
  margin: 0;
  padding: 0;
}
.sidebar-collective-icon-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--text-muted);
}

/* SIDEBAR POST CARD LIST */
.sidebar-post-card-list {
  display: flex;
  flex-direction: column;
}

.sidebar-post-card-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

/* SIDEBAR BADGE BUTTONS */
.sidebar-badge-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

/* SIDEBAR POST CARD */
.sidebar-post-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.7em; /* border radius here as well to ensure that the transition doesn't leave sharp edges */
  transition: background-color 0.2s ease;
}
.sidebar-post-card:hover {
  background-color: var(--navbar-color);
  border-radius: 0.7em;
}
.sidebar-post-card.active {
  background-color: var(--navbar-color);
  border-radius: 0.7em;
}
.sidebar-post-detail {
  display: flex;
  min-width: 0; /* ensures the track details to not stretch the width beyond the parent container */
  width: 100%;
  position: relative;
}
.sidebar-post-info {
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.sidebar-post-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
  gap: 0.3rem;
}
.sidebar-post-waveform-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none; /* prevents the waveform from blocking the click events of the track card */
  z-index: -1; /* ensures the waveform is behind the track details */
}
