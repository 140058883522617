.loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: loading-animation 1.5s infinite; /* Apply the animation */
}
.loading-component.bar {
  height: 1rem;
  border-radius: 0.7rem;
}
.loading-component.top-margin {
  margin-top: 0.5rem;
}
.loading-component.width-sm {
  width: 20%;
}
.loading-component.width-md {
  width: 50%;
}
.loading-component.width-lg {
  width: 80%;
}
.loading-component.height-md {
  height: 1.5rem;
}
.loading-component.height-lg {
  height: 2rem;
}
.loading-component.height-xl {
  height: 3rem;
}
