/* GLOBAL VARIABLES */
body {
  padding: 0;
  margin: 0;
  background: var(--background-color);
  color: var(--text-color);
  font-family: "Outfit", sans-serif;
}
.viewport-page-layout {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden; /* Prevents the page from scrolling */
  touch-action: none; /* Prevents the page from scrolling */
}

.public-page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
.protected-page-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin-left: auto; /* auto, so the layout is centered */
  margin-right: auto; /* auto, so the layout is centered */
  overflow: hidden;
  position: relative;
}

.main-layout {
  height: 100%;
  width: 100%;
  display: flex;
  max-width: var(--breakpoint-xl);
  margin-left: auto; /* auto, so the layout is centered */
  margin-right: auto; /* auto, so the layout is centered */
  overflow: hidden;
}
.container {
  color: var(--text-color);
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: 100%; /* removes the default snap width built in boostrap */
}
html {
  scroll-behavior: smooth;
}
