.navbar {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--navbar-color);
  color: var(--text-color);
  padding: 0;
  margin: 0;
}

.navbar-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--breakpoint-xl);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-left: auto;
}

.navbar-hamburger {
  display: none;
}
