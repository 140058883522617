/* BUTTONS */

/* GENERAL BUTTONS */
.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.8 !important; /* overrides the opacity of the children */
}
.button-disabled {
  cursor: default;
  opacity: 0.5;
}
.button-loading {
  cursor: wait;
  opacity: 0.5;
}

/* PRIMARY BUTTONS */
.btn-primary,
.btn-outline,
.btn-grey-outline,
.btn-black {
  height: auto;
  line-height: auto;
  box-sizing: none;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  outline: 2px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--background-color) !important;
  transition: background-color 0.2s ease;
  transition: color 0.2s ease;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: var(--primary-color-light) !important;
  color: var(--background-color) !important;
}
.btn-primary:disabled,
.btn-outline:disabled,
.btn-grey-outline:disabled,
.btn-black:disabled {
  background-color: var(--secondary-color-light) !important;
  color: var(--text-muted) !important;
  outline: none !important;
  pointer-events: none !important; /* disables any click properties */
}
.btn-outline {
  outline: 2px solid var(--primary-color) !important;
  background-color: var(--primary-background) !important;
  color: var(--primary-color) !important;
}
.btn-outline:hover {
  background-color: var(--primary-color-light) !important;
  color: var(--background-color) !important;
}
.btn-primary:active,
.btn-outline:active,
.btn-grey-outline:active,
.btn-black:active {
  background-color: var(--secondary-color) !important;
  color: var(--accent-color-2) !important;
  outline: 2px solid var(--accent-color-2) !important;
}
.btn-grey-outline {
  outline: 2px solid var(--text-muted) !important;
  background-color: var(--primary-background) !important;
  color: var(--text-muted) !important;
}
.btn-black {
  outline: 1px solid var(--border-color) !important;
  background-color: var(--background-color) !important;
  color: var(--text-muted) !important;
}
.btn-grey-outline:hover,
.btn-black:hover {
  background-color: var(--border-color) !important;
  color: var(--text-muted) !important;
}
.btn-primary.md,
.btn-outline.md,
.btn-grey-outline.md,
.btn-black.md {
  padding: 0.4rem;
}

/* ACTION BUTTONS */
.btn-circle-lg {
  background-color: var(--button-background) !important;
  padding: 0.3rem;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative; /* allows notification number to be on the top right */
  flex-shrink: 0; /* Prevent the image from becoming an oval */
}
.btn-circle-lg:hover {
  background-color: var(--button-background-hover) !important;
}
.btn-circle-lg-icon {
  font-size: 1.2rem;
  color: var(--icon-color);
}
.btn-square-md,
.btn-circle-sm,
.btn-circle-md {
  background-color: var(--button-background);
  padding: 0.3rem;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  flex-shrink: 0; /* Prevent the image from becoming an oval */
}
.btn-square-md {
  border-radius: 0.5rem;
}
.btn-square-md:hover,
.btn-circle-md:hover {
  background-color: var(--button-background-hover) !important;
}
.btn-circle-sm {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
}

/* REPLY FORM BUTTONS */
.btn-reply-form {
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  color: var(--primary-color);
}
.btn-reply-form:hover {
  opacity: 0.8;
}
.btn-reply-form:disabled {
  pointer-events: none;
  color: var(--text-muted);
}
.btn-reply-form.attachment {
  color: var(--text-muted);
}
.btn-reply-form.attachment:focus {
  outline: none;
}

/* TINY TEXT ICON BUTTONS */
.btn-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  line-height: 1; /* Reset line height to remove any extra space */
  color: var(--text-muted);
  flex-shrink: 0; /* Prevent the image from becoming an oval */
}
.btn-icon:hover {
  opacity: 0.8;
}
.btn-icon.sm {
  font-size: 0.9rem;
}
.btn-icon.md {
  font-size: 1.1rem;
}

/* ATTACHMENT BUTTONS */
.attachment-options {
  background-color: var(--button-background);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.attachment-options:hover {
  opacity: 0.8;
}
.attachment-remove {
  cursor: pointer;
}
.attachment-remove:hover {
  opacity: 0.8;
}

/* SEEK BUTTONS */
.btn-play {
  background-color: var(--background-color);
  border: 2px solid var(--button-background);
}

.btn-play div span {
  color: var(--text-muted);
}

.btn-pause,
.btn-play-active {
  background-color: var(--background-color);
  border: 2px solid var(--tertiary-color);
}

.btn-pause:hover,
.btn-play-active:hover {
  background-color: var(
    --tertiary-color-light
  ) !important; /* important needed to override the properties of btn-circle-md */
}

.btn-pause {
  background-color: var(--tertiary-color);
}

.btn-seek {
  cursor: pointer;
}
.btn-seek:hover {
  opacity: 0.8;
}

/* BADGE BUTTONS */
.btn-badge {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 700;
  padding-top: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 0.5rem;
  outline: 2px solid var(--primary-color);
  cursor: pointer;
}

.btn-badge.primary {
  background-color: var(--primary-color);
  color: var(--background-color);
}
.btn-badge.outline {
  color: var(--primary-color);
}
.btn-badge.outline-grey {
  outline: 2px solid var(--secondary-color-light);
  color: var(--secondary-color-light);
}
.btn-badge:hover {
  opacity: 0.8;
}

/* ADD TO PROJECT BUTTONS */

.btn-add {
  background-color: var(--background-color);
  border: 2px solid var(--success-color);
}

.btn-remove {
  background-color: var(--background-color);
  border: 2px solid var(--danger-color);
}

/* CREATE PARENT POST BUTTON */
.btn-add-parent-post {
  background-color: var(--primary-color);
}
.btn-add-parent-post span {
  color: var(--background-color);
}
.btn-add-parent-post:hover {
  background-color: var(--primary-color-light) !important;
}

/* CREATE PROJECT BUTTON */
.btn-create-project {
  background-color: var(--primary-color);
  border-radius: 0.6rem;
  width: 1.8rem;
  height: 1.8rem;
}
.btn-create-project span {
  color: var(--background-color);
}
.btn-create-project:hover {
  background-color: var(--primary-color-light) !important;
}

/* NAVBAR SIDEBAR TOGGLE BUTTON */
.btn-sidebar-toggle {
  cursor: pointer;
  font-size: 2rem;
  color: var(--text-muted);
}

/* CHECKBOX BUTTONS */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 0.2rem;
  outline: none;
  position: relative;
}

/* Style the checkbox when it's checked */
input[type="checkbox"]:checked {
  background: var(--primary-color);
}

/* Add a check mark when the checkbox is checked */
input[type="checkbox"]:checked:after {
  content: "\2714";
  position: absolute;
  color: var(--background-color);
  font-size: 0.8rem;
  top: -0.1rem;
  left: 0.15rem;
}

