.logo-container img {
  width: 100%;
  height: auto;
}

@keyframes loading-animation {
  0% {
    background-color: var(--button-background);
  }
  50% {
    background-color: var(--button-background-hover);
  }
  100% {
    background-color: var(--button-background);
  }
}
