.notifications-page-layout {
  height: 100%;
  width: 100%;
  max-width: 100%; /* removes the default snap width built in boostrap */
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* allows the page to scroll */
}
.notifications-container {
  display: flex;
  background-color: var(--navbar-color);
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.7rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
}
.notification-object-loading,
.notification-object {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.7rem;
  transition: background-color 0.1s ease;
  width: 100%;
}
.notification-object:hover {
  background-color: var(--button-background);
  cursor: pointer;
}
.notification-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.notification-unread-icon {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-dropdown-object {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  padding-top: 0.2rem;
}
.notification-dropdown-object-detail {
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: hidden; /* prevents text overflowing the parent */
}

