:root {
  /* Primary colors */
  --primary-color: #ffbf81;
  --primary-color-light: #ffdebc;
  --primary-color-dark: #673a3f;

  /* Secondary colors */
  --secondary-color: #2d231c;
  --secondary-color-dark: #201a15;
  --secondary-color-light: #6d645f;

  /* Tertiary colors */
  --tertiary-color: #334d3d;
  --tertiary-color-dark: #1a2e23;
  --tertiary-color-light: #3d5747;
  --tertiary-color-extra-light: #5a836a;

  /* Accent colors */
  --accent-color-1: #ff8c00;
  --accent-color-2: #ffa64e;
  --accent-color-3: #f0c497;

  /* Layout Colors */
  --background-color: #18191a;
  --navbar-color: #222324;

  /* Semantic colors - adjusted to complement your palette */
  --success-color: #caffca;
  --warning-color: #d4cda2;
  --danger-color: #fd9d9d;
  --info-color: #a8a8a8;
  --notification-color: #692f2f;
  --heart-color: #c01d00;
  --is-user-online-color: #26914f;

  /* Neutrals for flexibility and ease of use */
  --white: #ffffff;
  --light: #f2f2f2;
  --dark: #323232;
  --black: #000000;

  /* Utility colors */
  --text-color: #dbd9d9;
  --text-muted: #adadad;
  --border-color: #383838;
  --border-color-focused: #6b6b6b;
  --button-background: #383838;
  --button-background-hover: #4d4d4d;
  --icon-color: #dddddd;
  --placeholder-text-color: #727272;

  /* Breakpoints */
  --breakpoint-xs: 0; /* Extra small devices */
  --breakpoint-sm: 600px; /* Small devices */
  --breakpoint-md: 768px; /* Medium devices */
  --breakpoint-lg: 992px; /* Large devices */
  --breakpoint-xl: 1200px; /* Extra large devices */
}

