/* HEADINGS */
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 1.8rem;
  font-weight: 700;
}
h2 {
  font-size: 1.4rem;
  font-weight: 600;
}
h3 {
  font-size: 1.1rem;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}
h5 {
  font-size: 0.9rem;
  font-weight: 500;
}
h6 {
  font-size: 0.8rem;
  font-weight: 500;
}
h1.xxl {
  font-size: 2.6rem;
}
h1.xl {
  font-size: 2.2rem;
}
h1.lg {
  font-size: 2.2rem;
}
