.audio-player {
  border-top: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  width: 100%;
}
.audio-player-contents {
  align-items: center;
  max-width: var(--breakpoint-xl);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.audio-player-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}
.audio-player-row {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.audio-player-waveform-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  gap: 0.5rem;
  height: 1.5rem;
}
.audio-player-time-text {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--text-muted);
  width: 2.5rem; /* prevents the width of the time from changing */
  text-align: center;
}
.audio-player-playback-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}
.audio-player-expand-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.volume-slider {
  width: 100%;
  max-width: 10rem;
  justify-content: center;
  margin: auto;
}
