/* ICONS */
.loading-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.tiny-dropdown-icon {
  position: absolute;
  margin: 0;
  bottom: 0;
  right: -0.2rem;
  background-color: var(--button-background);
  color: var(--text-color);
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
}
.notification-bubble {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: var(--notification-color);
  color: var(--text-color);
  font-weight: 600;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
}
.is-user-online-icon {
  position: absolute;
  margin: 0;
  bottom: 0;
  right: 0;
  background-color: var(--is-user-online-color);
  border-radius: 100%;
  width: 0.6em;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid var(--background-color);
  cursor: pointer;
}

/* PROFILE ICON */
.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-dark);
  width: 2.5rem; /* set some default width and height in case none is specified in the component */
  height: 2.5rem; /* set some default width and height in case none is specified in the component */
  border-radius: 100%;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  position: relative; /* allows dropdown icon to be on bottom right of button */
}
.profile-icon:hover {
  opacity: 0.8;
}
.profile-icon img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
}
.profile-icon:focus,
.profile-icon:active {
  outline: none;
  box-shadow: none;
}

.new-post-icon {
  background-color: var(--notification-color);
  border-radius: 50%;
  cursor: pointer;
  height: 0.7rem;
  width: 0.7rem;
}

.play-button-icon {
  margin-left: 0.1rem; /* slight margin to make the icon appear more centered */
}

.tooltip-icon-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  line-height: 1.2rem;
  font-size: 1.2rem;
  padding: 1rem;
}

.tooltip-icon {
  color: var(--text-muted);
  margin: 0;
}

.tooltip-icon:hover {
  color: var(--text-color);
}
