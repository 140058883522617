.tooltip-inner {
  background-color: var(--button-background);
  color: var(--text-muted);
  font-size: 0.8rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
}

.tooltip-arrow {
  color: transparent;
}
