/* PARAGRAPHS */
p {
  font-size: 1rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
p.xxl {
  font-size: 1.8rem;
}
p.xl {
  font-size: 1.4rem;
}
p.lg {
  font-size: 1.2rem;
}
p.md {
  font-size: 0.9rem;
}
p.sm {
  font-size: 0.8rem;
}
p.xsm {
  font-size: 0.6rem;
  font-weight: 200;
}
p.bold {
  font-weight: 500;
}
