/* TRACK DETAILS */
.track-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: var(--navbar-color);
  padding: 0.8rem;
  border-radius: 1rem;
}
.track-container.highlighted {
  border: 1px solid var(--accent-color-3);
}
.track-container.highlighted:hover {
  opacity: 0.8;
  cursor: pointer;
}
.track-info {
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  overflow: hidden;
}
.track-detail-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
  gap: 0.3rem;
}
.track-comment-container {
  margin-top: 0.5rem;
}
.track-audio-container {
  height: 3rem; /* some height is required here in order for the waveform to be visible */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--background-color);
  border-radius: 1rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.track-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 0.7em; /* border radius here as well to ensure that the transition doesn't leave sharp edges */
  transition: background-color 0.1s ease;
}
.track-card:hover {
  background-color: var(--button-background);
  border-radius: 0.7em;
}
.track-card.active {
  background-color: var(--navbar-color);
  border-radius: 0.7em;
}
.track-details {
  display: flex;
  min-width: 0; /* ensures the track details to not stretch the width beyond the parent container */
  width: 100%;
  position: relative;
}
