/* REPLY CONTAINER */
.reply-section {
  height: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
.child-post-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem; /* creates space between the bottom of the thread and the next parent */
}
.reply-container {
  display: flex;
  flex-direction: column;
}
.reply-object {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.reply-object-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reply-object-details {
  padding: 0.7rem;
  border-radius: 1rem;
  background-color: var(--navbar-color);
}
.reply-object-details.highlighted {
  border: 1px solid var(--accent-color-3);
}
.reply-object-details.highlighted:hover {
  opacity: 0.8;
  cursor: pointer;
}
.reply-object-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}
.reply-object-comment {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.reply-object-actions {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0.5rem;
  margin-left: 1rem;
}
/* the embeded child posts within the actual reply */
.reply-object-child-posts {
  height: 100%;
  display: flex; /* allows the child posts height to expand to 100% - enabling the thread line to extend */
}

/* ATTACHMENTS CONTAINER */
.attachments-container {
  width: 100%;
  color: var(--text-muted);
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* wraps the objects within the container */
  gap: 0.4rem;
  margin-top: 0.5rem;
  align-items: flex-end; /* aligns the condensed cards to the bottom of the container */
}
.attachment-object {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--background-color);
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.attachment-object.condensed {
  height: 1.6rem;
  max-width: 5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: max-width 0.1s ease-in-out, border-radius 0.1s ease-in-out,
    height 0.1s ease-in-out;
}
.attachment-object.expanded {
  height: 3rem;
  max-width: 15rem;
  border-radius: 1rem;
  transition: max-width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
    height 0.2s ease-in-out;
}
.attachment-object.preview-lg,
.attachment-object.preview {
  min-height: 1.6rem;
  max-width: 10rem;
  border-radius: 0.5rem;
}
.attachment-object.preview-lg {
  max-width: 20rem;
}

.attachment-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
}
.attachment-icon {
  font-size: 1.5rem;
}

