.table {
  margin: 0;
}

.table thead th {
  outline: none;
  border: none;
  font-weight: 200;
  font-size: 1rem;
  color: var(--text-muted);
}

.table th,
.table td {
  border-top: 1px solid var(--border-color);
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-color);
}

.table-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
