::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--background-color);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--button-background) var(--background-color);
}
