/* DROPDOWN */
.dropdown-menu {
  background-color: var(--button-background);
  font-size: 0.9rem;
  border-radius: 0.8rem;
  max-width: 22rem;
  min-width: 12rem;
  margin: 0;
  padding: 0.5rem;
}
.dropdown-item {
  color: var(--text-color);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  color: var(--text-color);
}
.dropdown-item:hover {
  background-color: var(--button-background-hover);
  color: var(--text-color);
}
.dropdown-divider {
  border-top: 1px solid var(--button-background-hover);
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.dropdown-header {
  margin: 0;
  padding: 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 700;
}

