/* AUTH FORM */
.auth-form {
  text-align: center;
  background-color: var(--navbar-color);
  border-radius: 0.7rem;
  padding: 1.5rem;
  max-width: 25rem;
  min-width: 15rem;
  width: 100%;
  margin: 2rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 0 10px var(--secondary-color);
}

/* REGISTER FORM */
.register-form {
  text-align: center;
  border-radius: 0.7em;
  padding: 1.5rem;
  max-width: 30rem;
  width: 100%;
  margin: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 0 10px var(--secondary-color);
}

/* FORM FIELDS */

.loading-field {
  pointer-events: none;
  opacity: 0.8;
}

/* TIP TAP */

.tiptap {
  :first-child {
    margin-top: 0;
    padding: 0.3rem;
    color: var(--text-color);
  }

  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    color: var(--placeholder-text-color);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

.tiptap:focus {
  outline: none;
}

/* RICH TEXT EDITOR */

.rte-container {
  /* RTE container has the items inside overlapping so the content and the placeholder are on top of each other without positioning them absolutely*/
  position: relative;
  display: grid;
}
.rte-content,
.rte-placeholder {
  padding: 0.3rem; /* ensure padding matches */
  grid-area: 1 / 1 / 2 / 2; /* overlap both elements */
}
.rte-content {
  background-color: transparent;
  margin-top: auto;
  color: var(--text-color);
  outline: none;
  height: 100%;
}
.rte-content:focus {
  outline: none;
}
.rte-placeholder {
  color: var(--placeholder-text-color);
  font-weight: 300;
  font-size: 1rem;
  pointer-events: none; /* ensures the placeholder cannot be clicked */
  white-space: pre-wrap; /* ensures the text wraps */
}
.rte-mention,
[data-beautiful-mention] {
  background-color: var(--button-background);
  padding: 0.2rem;
  padding-top: 0;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  color: var(--text-color);
  transition: background- 0.1s ease-in-out;
}
.rte-mention:hover,
[data-beautiful-mention]:hover {
  background-color: var(--button-background-hover);
}
.rte-mention.user,
[data-beautiful-mention^="@"] {
  color: var(--accent-color-3);
}

/* MODAL FORM */
.modal-form {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 30rem;
  width: 100%;
}

/* FORM CONTAINER */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form-text-input {
  width: 100%;
  border-radius: 0.7rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  padding: 0.8rem;
  line-height: 1rem;
  font-weight: 300;
  color: var(--text-color);
  resize: none; /* hides the resizing element */
  overflow: hidden; /* prevents scrollbar */
  transition: border 0.2s ease-in-out;
}
.form-text-input:focus {
  border: 1px solid var(--border-color-focused);
  outline: none;
}

/* SELECT LIST INPUT */
.form-select-input-item {
  font-weight: 300;
}
/* SEARCH INPUT */
.form-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.7rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  font-weight: 300;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--text-color);
  resize: none; /* hides the resizing element */
  overflow: hidden; /* prevents scrollbar */
  transition:
    height 0.2s ease-in-out,
    border 0.2s ease-in-out;
}
.form-search-container:focus-within {
  border: 1px solid var(--border-color-focused);
  outline: none;
}
.form-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 0.7rem;
}
.form-search-icon {
  color: var(--text-muted);
}
.form-search-input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-weight: 300;
  font-size: 1rem;
  padding: 0.7rem;
  line-height: 1rem;
  color: var(--text-color);
  resize: none; /* hides the resizing element */
  overflow: hidden; /* prevents scrollbar */
}
.form-search-input:focus {
  outline: none;
}

.form-search-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding: 0.5rem;
  gap: 0.5rem;
}

.form-search-result-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

.form-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--accent-color-3);
}

.form-search-button:hover {
  opacity: 0.8;
}

/* NEW POST FORM */

.new-post-form-files-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: box-shadow 0.2s;
}
.new-post-form-files-container.drag-active {
  box-shadow: 0 0 10px var(--primary-color);
}
.new-post-form-input-fieldname {
  text-align: left;
  padding-left: 0.7rem;
  padding-bottom: 0.1rem;
  color: var(--text-muted);
  font-size: 0.9rem;
}
.new-post-form-input {
  text-align: left;
  width: 100%;
  border-radius: 0.7rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  padding: 0.8rem;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--text-color);
  resize: none; /* hides the resizing element */
  overflow: hidden; /* prevents scrollbar */
  transition: border 0.2s ease-in-out;
}
.new-post-form-input:focus {
  border: 1px solid var(--border-color-focused);
  outline: none;
}
/* Custom placeholder text given we are using a div for text wrapping instead of input or textarea */
.new-post-form-input[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: var(--placeholder-text-color); /* Placeholder text color */
  display: block; /* Make it block to consume the full width */
  cursor: text;
}
.new-post-form-buttons {
  padding: 0.3rem;
  display: flex;
  gap: 0.7rem;
  margin-left: auto;
}
.new-post-form-description {
  text-align: left;
  border: 1px solid var(--border-color);
  border-radius: 0.7rem;
  padding: 0.5rem;
  transition: border 0.2s ease-in-out;
}
.new-post-form-description:focus-within {
  border: 1px solid var(--border-color-focused);
  outline: none;
}

/* REPLY FORM */
.reply-form-container {
  width: 100%; /* takes the full width of the parent */
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem; /* space bewteen the post form and the top of the hightest comment objet */
}
.reply-form {
  width: 100%; /* takes the full width of the parent */
  border: 1px solid var(--border-color);
  box-shadow: 0 0 10px var(--secondary-color);
  background-color: var(--navbar-color);
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  transition:
    box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;
}
/* focus based all elements within the form */
.reply-form:focus-within {
  border: 1px solid var(--border-color-focused);
  outline: none;
}
/* active drag and drop effect */
.reply-form.drag-active {
  box-shadow: 0 0 10px var(--primary-color);
}
.reply-form-contents {
  width: 100%; /* takes the full width of the parent */
  display: flex;
  flex-direction: row;
  gap: 0.7rem; /* space between the input and the buttons */
}
.reply-form-text-container {
  width: 100%; /* takes the full width of the parent */
  min-width: 10rem; /* allows some minimum width so container doesn't condense when typing text in the field */
}
.reply-form-buttons {
  display: flex;
  gap: 0.3rem; /* gap between the buttons themselves */
  margin-top: auto; /* Pushes the buttons to the bottom */
}
.reply-form-input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0.3rem 0.6rem;
  font-weight: 300;
  font-size: 1rem;
  color: var(--text-color);
  resize: none; /* hides the resizing element */
  overflow-y: hidden; /* prevents scrollbar */
}
.reply-form-input:focus {
  outline: none;
}
/* Custom placeholder text given we are using a div for text wrapping instead of input or textarea */
.reply-form-input[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #727272; /* Placeholder text color */
  display: block; /* Make it block to consume the full width */
  cursor: text;
}

/* EDITABLE IMAGE */
.editable-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editable-image-container-content {
  transition: opacity 0.2s;
}

.editable-image-container-content:hover {
  opacity: 0.3;
}

.editable-image-container-icon {
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
}
.editable-image-remove-icon {
  padding: 0.3rem;
  color: var(--text-muted);
  cursor: pointer;
  font-size: 0.8rem;
}

/* only show the icon when the image is hovered */
.editable-image-container:hover .editable-image-container-icon {
  opacity: 1;
}
