footer {
  text-align: center;
  width: 100%;
  padding: 1rem;
  margin: 0;
  margin-top: auto;
}

.footer-container {
  position: relative;
}

