/* HOME PAGE */

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
.section-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 100%;
}
.section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  gap: 3rem;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  max-width: 30rem;
  min-width: 15rem;
  margin: 2rem;
}
.section-2 h1 {
  max-width: 40rem;
}
.feature-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.feature-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  padding: 1.5rem;
  max-width: 30rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 0 10px var(--secondary-color);
  gap: 1rem;
  border-radius: 1rem;
  margin: 1rem;
}
.feature-card p {
  margin-top: 0.5rem;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  color: var(--accent-color-2);
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid var(--accent-color-2);
  box-shadow: 0 0 10px var(--secondary-color);
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 25rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  padding: 1rem;
}
.button-container button {
  width: 100%;
}
.button-container h1 {
  margin-bottom: 2rem;
}

/* REGISTER PAGE */
.register-page-section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1.5rem;
  width: 100%;
}

/* Page Responsiveness */

@media (max-width: 768px) {
  .section-1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .section-2 {
    margin-top: 3.5rem;
    margin-bottom: 4rem;
  }
  .button-container {
    margin-top: 2rem;
  }
  .auth-form {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .feature-card-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
