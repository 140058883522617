/* TEXT FORMATTING */
.text-muted {
  color: var(--text-muted) !important;
}
.text-bold {
  font-weight: 500;
}
.text-extra-bold {
  font-weight: 800;
}
.text-accented {
  color: var(--accent-color-3);
}
.text-placeholder {
  color: var(--placeholder-text-color);
}
.text-center {
  text-align: center;
}
a {
  color: var(--accent-color-1);
  text-decoration: none;
}
a:hover {
  color: var(--accent-color-1);
}
strong {
  font-weight: 600;
  color: var(--accent-color-1);
}
.link {
  color: var(--accent-color-3);
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: var(--primary-color);
}
.track-timestamp-link {
  color: var(--tertiary-color-extra-light);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.track-timestamp-link:hover {
  opacity: 0.8;
}

/* FONT BEHAVIOURS */
.text-overflow-trim {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
}

/* Breaks text at the end of a very long line such as url*/
.text-break {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* prevents text from being selected */
.text-prevent-selecting {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Wraps the text at 1 row */
.text-wrap-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Wraps the text at 2 rows */
.text-wrap-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Wraps the text at 2 rows */
.text-wrap-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

