.custom-card-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.custom-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: var(--navbar-color);
  gap: 0.5rem;
  border-radius: 1rem;
  position: relative;
}

.custom-card-column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.custom-card-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
}
